<template>
    <div>
        <template v-if="ver_condusef">
            <div class="row mt-3 mb-3">
                <h1 class="col-sm-12 text-right">Condusef</h1>
            </div>
            <div class="d-flex flex-wrap">
                <div class="col-md-4" v-if="$auth.can('condusef', 'quejas_listar')">
                    <div class="bpb-card">
                        <div class="card-body">
                            <h4 class="card-title">Quejas</h4>
                            <p class="card-subtitle text-muted">Administración de quejas</p>
                            <router-link :to="{ name: 'condusef-quejas' }"
                                class="btn complementary-btn">Acceder</router-link>
                        </div>
                    </div>
                </div>

              <div class="col-md-4" v-if="$auth.can('condusef', 'consultas_listar')">
                <div class="bpb-card">
                  <div class="card-body">
                    <h4 class="card-title">Consultas REUNE</h4>
                    <p class="card-subtitle text-muted">Administración de consultas</p>
                    <router-link :to="{ name: 'condusef-consultas-reune' }"
                                 class="btn complementary-btn">Acceder</router-link>
                  </div>
                </div>
              </div>

              <div class="col-md-4" v-if="$auth.can('condusef', 'reclamaciones_listar')">
                <div class="bpb-card">
                  <div class="card-body">
                    <h4 class="card-title">Reclamaciones REUNE</h4>
                    <p class="card-subtitle text-muted">Administración de reclamaciones</p>
                    <router-link :to="{ name: 'condusef-reclamaciones-reune' }"
                                 class="btn complementary-btn">Acceder</router-link>
                  </div>
                </div>
              </div>

              <div class="col-md-4" v-if="$auth.can('condusef', 'aclaraciones_listar')">
                <div class="bpb-card">
                  <div class="card-body">
                    <h4 class="card-title">Aclaraciones REUNE</h4>
                    <p class="card-subtitle text-muted">Administración de aclaraciones</p>
                    <router-link :to="{ name: 'condusef-aclaraciones-reune' }"
                                 class="btn complementary-btn">Acceder</router-link>
                  </div>
                </div>
              </div>
            </div>
        </template>

        <template v-if="$auth.can('condusef', 'usuario_admin')">
            <div class="row">
                <h2 class="col-sm-12 text-right">Administración</h2>
            </div>

            <div class="d-flex flex-wrap">
                <div v-if="$auth.can('condusef', 'configuracion_crear')" class="col-md-4">
                    <div class="bpb-card">
                        <div class="card-body">
                            <h4 class="card-title">Configuración</h4>
                            <p class="card-subtitle text-muted">Administración de Configuración</p>
                            <router-link :to="{ name: 'condusef-admin-configuracion' }"
                                class="btn complementary-btn">Acceder</router-link>
                        </div>
                    </div>
                </div>

                <div v-if="$auth.can('condusef', 'catalogos_crear')" class="col-md-4">
                    <div class="bpb-card">
                        <div class="card-body">
                            <h4 class="card-title">Catálogos</h4>
                            <p class="card-subtitle text-muted">Administración de Catálogos</p>
                            <router-link :to="{ name: 'condusef-admin-catalogos' }"
                                class="btn complementary-btn">Acceder</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {

  computed:{
    ver_condusef(){
      return this.$auth.can('condusef', 'quejas_listar') || this.$auth.can('condusef', 'consultas_listar')
    }
  },

    mounted() {
        let menus = []

        if (this.ver_condusef) {
            let condusef = { title: 'Condusef' };

            if (this.$auth.can('condusef', 'quejas_listar'))
              condusef['condusef-quejas'] = 'Quejas';


          if (this.$auth.can('condusef', 'consultas_listar'))
            condusef['condusef-consultas-reune'] = 'Consultas REUNE';

          if (this.$auth.can('condusef', 'reclamaciones_listar'))
            condusef['condusef-reclamaciones-reune'] = 'Reclamaciones REUNE';

          if (this.$auth.can('condusef', 'aclaraciones_listar'))
            condusef['condusef-aclaraciones-reune'] = 'Aclaraciones REUNE';

            menus.push(condusef);
        }

        if (this.$auth.can('condusef', 'usuario_admin')) {
            let admin = { title: 'Administración' };

            if (this.$auth.can('condusef', 'configuracion_crear'))
                admin['condusef-admin-configuracion'] = 'Configuración';

            if (this.$auth.can('condusef', 'catalogos_crear'))
                admin['condusef-admin-catalogos'] = 'Catálogos';

            menus.push(admin);
        }


        this.$store.commit('setMenu', menus);

    }
}
</script>